<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                Import from WOS
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to publications"
              @click="$router.push({name: 'researcher.publications.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Publications settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card card--table">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Upload file</a>
                </h3>
              </div>
              <div class="card-content collapse show">

                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="mb-1">
                        <div
                          class="input-group custom-file-button"
                        >
                          <label
                            class="input-group-text"
                            for="formFile"
                          >Select file</label>
                          <input
                            id="formFile"
                            :ref="`file-import`"
                            class="form-control"
                            type="file"
                          >
                        </div>
                      </div>
                      <div class="mb-1">
                        <button
                          :disabled="checkingDuplicates"
                          class="btn btn-dark"
                          @click="importFile"
                        >
                          <i data-feather="upload-cloud" /> Upload file
                        </button>
                        <button
                          v-if="publications.length > 0"
                          :disabled="checkingDuplicates"
                          class="btn btn-danger ms-1"
                          @click="$store.dispatch('publicationImport/clearItems')"
                        >
                          <i data-feather="x" /> Remove loaded publications
                        </button>
                        <div
                          v-if="checkingDuplicates"
                          class="loading custom-loading mt-1"
                        >
                          <div class="effect-1 effects" />
                          <div class="effect-2 effects" />
                          <div class="effect-3 effects" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template>
              <div
                v-if="ok != 0"
                class="alert alert-success"
              >
                <div class="alert-body">
                  <i data-feather="check-circle" /> <strong>{{ ok }}</strong> publications loaded successfully
                </div>
              </div>

              <div
                v-if="duplicates != 0"
                class="alert alert-danger"
              >
                <div class="alert-body">
                  <i data-feather="alert-triangle" /> <strong>{{ duplicates }}</strong> publications marked as duplicates
                </div>
              </div>

              <div
                v-if="maybes != 0"
                class="alert alert-warning"
              >
                <div class="alert-body">
                  <i data-feather="alert-triangle" /> <strong>{{ maybes }}</strong> publications marked as maybe
                </div>
              </div>

              <div
                v-if="updateds != 0"
                class="alert alert-info"
              >
                <div class="alert-body">
                  <i data-feather="alert-circle" /> <strong>{{ updateds }}</strong> publication identified as updated
                </div>
              </div>

              <CompleteTable
                v-if="!checkingDuplicates"
                :sorted-field="sortedField"
                :data="publications"
                :sort-mapping="sortMapping"
                :title="'publications'"
                :total="publications.length"
                :fetch-path="'publicationImport/fetch'"
                :export-path="'publicationImport/export'"
                :filter-path="'publicationImport/filter'"
                :actions="true"
                :hide-bottom="true"
                @selectedItems="saveSelectedItems"
              >
                <template v-slot="{field}">
                  <td class="text-end">
                    <span
                      class="dis-ib"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Edit area and group"
                    ><a
                      class="btn btn-icon rounded-circle btn-flat-dark"
                      :href="$router.resolve({name: 'admin.publications.import.edit', params: { id: field.wos_id }}).href"
                      target="_blank"
                    ><i data-feather="edit-3" /></a></span>
                    <span
                      class="dis-ib"
                      @click="$store.dispatch('publicationImport/delete', field.wos_id)"
                    ><a
                      class="btn btn-icon rounded-circle btn-flat-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#modalDeleteWithInput"
                    ><i data-feather="trash-2" /></a></span>
                  </td>
                </template>
                <template #customfilters>
                  <div class="mb-1">
                    <label
                      for="nameseniorcall"
                      class="form-label"
                    >WOS ID</label>
                    <input
                      type="text"
                      class="form-control"
                      name="nameseniorcall"
                      @change="saveFilter($event.target.value, 'wos_id')"
                    >
                  </div>
                  <div class="mb-1">
                    <label
                      for="nameseniorcall"
                      class="form-label"
                    >Title</label>
                    <input
                      type="text"
                      class="form-control"
                      name="nameseniorcall"
                      @change="saveFilter($event.target.value, 'title')"
                    >
                  </div>
                  <div class="mb-1">
                    <label
                      for="nameseniorcall"
                      class="form-label"
                    >Year</label>
                    <input
                      type="text"
                      class="form-control"
                      name="nameseniorcall"
                      @change="saveFilter($event.target.value, 'year')"
                    >
                  </div>
                  <div class="mb-1">
                    <label
                      for="nameseniorcall"
                      class="form-label"
                    >Journal</label>
                    <input
                      type="text"
                      class="form-control"
                      name="nameseniorcall"
                      @change="saveFilter($event.target.value, 'journal')"
                    >
                  </div>
                  <div class="mt-1 mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Duplicated</label>
                    <select
                      class="form-select select2"
                      @change="saveFilter($event.target.value, 'duplicated')"
                    >
                      <option
                        selected
                        disabled
                        value=""
                      >
                        Select one option
                      </option>
                      <option value="0">
                        No
                      </option>
                      <option value="1">
                        Yes
                      </option>
                      <option value="2">
                        Updated
                      </option>
                      <option value="3">
                        Maybe
                      </option>
                    </select>
                  </div>
                </template>
                <template #info-sheet-item>
                  <div class="offcanvas-body offcanvas-body--view">

                    <h4 class="mb-2">
                      <strong class="text-success">{{ selectedItems.length }}</strong> selected imported publications
                    </h4>

                    <div class="mt-1 mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Select publications (Duplicated column)</label>
                      <select
                        class="form-select select2"
                        @change="selectData($event)"
                      >
                        <option
                          selected
                          disabled
                          value=""
                        >
                          Select one option
                        </option>
                        <option value="0">
                          No
                        </option>
                        <option value="1">
                          Yes
                        </option>
                        <option value="2">
                          Updated
                        </option>
                        <option value="3">
                          Maybe
                        </option>
                      </select>
                    </div>

                    <ul
                      id="basic-list-group"
                      class="list-group"
                    >
                      <li class="list-group-item draggable">
                        <div class="d-flex">
                          <div class="more-info">
                            <div class="form-check">
                              <input
                                id="tableAction1"
                                v-model="actions.convertToPubli"
                                type="checkbox"
                                class="form-check-input"
                              >
                              <label
                                class="form-check-label"
                                for="tableAction1"
                              >Convert WOS to publication</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item draggable">
                        <div class="d-flex">
                          <div class="more-info">
                            <div class="form-check">
                              <input
                                id="tableAction2"
                                v-model="actions.convertToPubliSigned"
                                type="checkbox"
                                class="form-check-input"
                              >
                              <label
                                class="form-check-label"
                                for="tableAction2"
                              >Convert WOS to publication (signed as ICREA)</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item draggable">
                        <div class="d-flex">
                          <div class="more-info">
                            <div class="form-check">
                              <input
                                id="tableAction4"
                                v-model="actions.deleteItem"
                                type="checkbox"
                                class="form-check-input"
                              >
                              <label
                                class="form-check-label"
                                for="tableAction4"
                              >Delete item</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item draggable">
                        <div class="d-flex">
                          <div class="more-info">
                            <div class="form-check">
                              <input
                                id="tableAction5"
                                v-model="actions.deleteDuplicates"
                                type="checkbox"
                                class="form-check-input"
                              >
                              <label
                                class="form-check-label"
                                for="tableAction5"
                              >Delete duplicates</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item draggable">
                        <div class="d-flex">
                          <div class="more-info">
                            <div class="form-check">
                              <input
                                id="tableAction6"
                                v-model="actions.updatedPubli"
                                type="checkbox"
                                class="form-check-input"
                              >
                              <label
                                class="form-check-label"
                                for="tableAction6"
                              >Update publication</label>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="offcanvas-footer mt-auto">
                    <a
                      title="apply actions"
                      class="btn btn-primary mb-1 d-grid w-100"
                      @click="applyActions"
                    >Apply actions</a>
                  </div>
                </template>
              </CompleteTable>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
  },
  data() {
    return {
      checkingDuplicates: false,
      status: 0,
      selectedItems: [],
      actions: {
        convertToPubli: false,
        convertToPubliSigned: false,
        deleteItem: false,
        deleteDuplicates: false,
      },
      sortedField: 'Order',
      defaultFields: [
        {
          name: 'Wos Id',
          checked: true,
          order: 1,
        },
        {
          name: 'Title',
          checked: true,
          order: 2,
        },
        {
          name: 'Year',
          checked: true,
          order: 3,
        },
        {
          name: 'Journal',
          checked: true,
          order: 4,
        },
        {
          name: 'Duplicated',
          checked: true,
          order: 5,
        },
        {
          name: 'Authors',
          checked: true,
          order: 6,
        },
        {
          name: 'Type',
          checked: true,
          order: 7,
        },
      ],
      sortMapping: {
        'Wos Id': 'wos_id',
        Title: 'title',
        Year: 'year',
        Journal: 'publication_name',
        Duplicated: 'duplicated_table',
        Authors: 'authors',
        Type: 'publication_type',
      },
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      publications: 'publicationImport/items',
      updateds: 'publicationImport/updateds',
      duplicates: 'publicationImport/duplicates',
      ok: 'publicationImport/ok',
      maybes: 'publicationImport/maybes',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    window.onfocus = async () => {
      if (!this.checkingDuplicates) {
        this.checkingDuplicates = true
        await this.$store.dispatch('publicationImport/loadPublications', this.filters)
        setTimeout(() => {
          this.checkingDuplicates = false
        }, 200)
      }
    }
    await this.$store.dispatch('publicationImport/loadPublications', this.filters)
    this.items = this.itemsImported
    await this.$store.dispatch('modals/fetchUserFields', 'publications-import')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'publications-import',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async importFile() {
      this.checkingDuplicates = true
      try {
        const items = []
        const file = this.$refs['file-import'].files[0]
        // FileReader Object
        const reader = new FileReader()

        // Read file as string
        reader.readAsText(file)

        // Load event
        reader.onload = event => {
          // Read file data
          const csvdata = event.target.result

          // Split by line break to gets rows Array
          const rowData = csvdata.split('\n')

          // Loop on the row Array (change row=0 if you also want to read 1st row)
          for (let row = 1; row < rowData.length; row++) {
            // Split by tab to get column Array
            const rowColData = rowData[row].split('\t')
            if (rowColData[70]) {
              const item = {
                wos_id: rowColData[70].replace('WOS:', ''),
                doi: rowColData[57],
                title: rowColData[8],
                authors: rowColData[1],
                publication_type: rowColData[13],
                publication_name: this.capitalizeFirstLetter(rowColData[9].toLowerCase()),
                noservol_pages: `${rowColData[47]} - ${rowColData[48]} - ${rowColData[53]} - ${rowColData[54]} - ${rowColData[55]}`,
                year: rowColData[46],
                duplicated: false,
                has_early_access: rowColData[13].includes('Early Access'),
                users: [],
              }
              items.push({
                ...item,
                full_reference: this.getReference(item),
              })
            }
          }
          this.$store.dispatch('publicationImport/saveItems', items).then(() => {
            this.checkingDuplicates = false
            feather.replace({
              width: 14,
              height: 14,
            })
          })
          this.status = 1
        }
      } catch (e) {
        console.log(e)
        this.checkingDuplicates = false
        this.status = 0
      }
    },
    getReference(publication) {
      let link = ''
      link = `'<a href="${publication.doi}">${publication.title}</a>', <em>${publication.publication_name}</em>`
      return `${publication.authors.length > 250 ? `${publication.authors.split(';')[0]} et al.` : publication.authors.replaceAll(',', '').replaceAll(';', ',')} ${publication.year}, ${link}, ${publication.noservol_pages ?? (publication.wos_data && publication.wos_data.noservol_pages ? publication.wos_data.noservol_pages : '')}.`
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    applyActions() {
      const outside = this
      Vue.swal({
        title: 'Perform the following actions on the selected publications?',
        html: this.getActionsString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, apply!',
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('publicationImport/applyActions', { actions: this.actions, items: this.selectedItems }).then(() => {
            this.checkingDuplicates = true
            this.$store.dispatch('modals/toggleInfoItemSheet', false)
            this.$toastr.success('', 'Actions applied successfully!')
            this.$store.dispatch('publicationImport/loadPublications')
            setTimeout(() => {
              this.checkingDuplicates = false
            }, 100)
          })
        } else if (this.selectedItems.length === 0) {
          this.$toastr.error('', 'No items selected!')
        }
      })
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    getActionsString() {
      let str = ''

      if (this.actions.convertToPubli) {
        str += '<p class="text-success">Convert WOS to publication</p>'
      }

      if (this.actions.convertToPubliSigned) {
        str += '<p class="text-success">Convert WOS to publication and signed by Icrea</p>'
      }

      if (this.actions.deleteItem) {
        str += '<p class="text-warning">Delete item</p>'
      }

      if (this.actions.deleteDuplicates) {
        str += '<p class="text-warning">Delete duplicates</p>'
      }

      return str
    },
    selectData(event) {
      const data = this.publications.filter(e => e.duplicated === parseInt(event.target.value, 0))
      this.selectedItems = data
    },
  },
}
</script>
